import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, catchError, iif, map, of, switchMap } from 'rxjs';
import { Blob } from 'activestorage';
import { getTime } from 'date-fns';
import { Response } from '@parashift/ngx-airbrush';
import { ActiveStorageAttachment, Document, DocumentGroup, DocumentTaskStatus, ListFilter, WorkflowStep, UrlVars } from '@parashift/shared/models';
import { DocumentType, GroupByType } from '@parashift/shared/types';
import { ServiceDecorator } from '../decorators/service.decorator';
import { BaseApiService } from './../base';
import { QueryParamsService } from '../../query-params.service';
import { SessionService } from '../../session.service';
import { AUTHORIZATION, AuthorizationMethod } from '@parashift/shared/constants';
import { ENVIRONMENT_CONFIG } from '@parashift/shared/environment-config';

@Injectable({
  providedIn: 'root'
})
@ServiceDecorator({
  model: () => Document,
  endpointUrl: DocumentType
})
export class DocumentService extends BaseApiService<Document> {
  className = DocumentService;

  constructor(
    http: HttpClient,
    queryParamsService: QueryParamsService,
    private sessionService: SessionService,
    @Inject(ENVIRONMENT_CONFIG) private environment: any
  ) {
    super(http, queryParamsService);
    this.baseUrl = this.environment.endpoints.individual_extraction;
    this.apiVersion = this.environment.endpoints.individual_extraction_version
  }

  // This method returns a single locked document
  // If there is a document_id we use /documents/:id/lock
  // If we ask for the next document in the batch we can apply a listFilter and use /documents/validation/lock
  // This method should only be used by the locked-document-or-batch-register!
  // It then takes care of renewing the lock_timeout, unlocking and preloading the next document
  findAndLockRecord(tenant_id: number, document_id?: string, listFilter?: ListFilter): Observable<Document> {
    const params = listFilter ? this.queryParamsService.getParams(listFilter, true) : undefined;
    const urlVars: UrlVars = { postfix: 'lock' + ( params ? '?' + params : '')};
    urlVars.after_endpoint_url = document_id ?  undefined : 'validation';

    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl(document_id);
    const httpCall = this.http.put(url, {}, { observe: 'response' }) as Observable<Response>;

    return httpCall.pipe(
      map((res: Response) => this.successful(res)),
      map((res: Response) => this.extract(res) as Document),
      catchError((res: any) => this.handleError(res))
    );
  }

  isRecordLocked(document: Document): boolean {
    if (document.lock_timeout && document.lock_user_id) {
      const now = getTime(new Date());
      const current_lock_timeout = getTime(document.lock_timeout);
      return current_lock_timeout >= now && document.lock_user_id === this.sessionService.user_id
             ? false
             : (current_lock_timeout > now) ? true : false;
    }
    return false;
  }

  isRecordLockedByUser(document: Document): boolean {
    if (document.lock_timeout && document.lock_user_id) {
      const now = getTime(new Date());
      const current_lock_timeout = getTime(document.lock_timeout);
      return current_lock_timeout >= now && document.lock_user_id === this.sessionService.user_id
             ? true
             : false;
    }

    return false;
  }

  isLoading(document: Document): boolean {
    return document.id.includes('loading');
  }

  createFromDirectUpload(fileHandler: Blob,
                         tenant_id: number,
                         initialModel?: Partial<Document['plainModel']>) {
    this.setProperties(tenant_id);

    const document = new Document({});
    document.source_files = [
      {
        blob_signed_id: fileHandler['signed_id'],
        name: 'source_files'
      } as ActiveStorageAttachment
    ];

    if (initialModel) {
      document.plainModel = initialModel as Document['plainModel'];
    }

    return this.saveRecord(document, tenant_id);
  }

  resetDocument(document: Document) {
    const urlVars = {
      postfix: 'reset'
    };

    return this.findRecord(document.id, undefined, document.tenant_id, urlVars);
  }

  setValidationStepStatus(status: DocumentTaskStatus, document: Document) {
    const currentTask = document.getCurrentTask();

    if (currentTask) {
      currentTask.status = status;
    }
  }

  setNextTask(documentId: string, currentTaskId: string, tenantId: number, nextTaskId: string) {
    const urlVars = { postfix: documentId + '/document_tasks/' + currentTaskId + '/next' };

    this.setProperties(tenantId, urlVars);
    const url = this.buildUrl();
    return this.http.post(url, { next_document_task_id: nextTaskId });
  }

  setTaskToDone(step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    return this.setTaskStatus('done', step, documentId, currentTaskId, tenantId);
  }

  forceTaskToDone(step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    return this.setTaskStatus('force_done', step, documentId, currentTaskId, tenantId);
  }

  setTaskToFailed(step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    return this.setTaskStatus('fail', step, documentId, currentTaskId, tenantId);
  }

  private setTaskStatus(status: 'done' | 'force_done' | 'fail', step: WorkflowStep, documentId: string, currentTaskId: string, tenantId: number) {
    const urlVars = { postfix: documentId + '/document_tasks/' + currentTaskId + '/' + status };

    this.setProperties(tenantId, urlVars);
    const url = this.buildUrl();
    return this.http.post(url, { step });
  }

  exportDocuments(format: 'json' | 'csv' = 'json', listFilter: ListFilter, tenant_id?: number) {
    const filter = new ListFilter({
      extra_fields: listFilter.extra_fields,
      fields: listFilter.fields,
      filter: listFilter.filter,
      include: listFilter.include,
      sorting: listFilter.sorting
    });
    const params = this.queryParamsService.getParams(filter, true);
    const urlVars = { postfix: 'download' + ( params ? '?' + params : '') };

    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl();

    return this.http.get(url, { params: { format }});
  }

  getPayload(id: string, api_key: string) {
    const listFilter = new ListFilter({ include: 'document_fields' });
    const params = this.queryParamsService.getParams(listFilter, true);
    const urlVars = { api_version: 'v2', postfix: id + ( params ? '?' + params : '') };
    this.setProperties(undefined, urlVars);
    const url = this.buildUrl();
    let returnedUrl = decodeURI(url);
    returnedUrl = returnedUrl.replace(/%2C/g, ',');
    const context = new HttpContext().set(AUTHORIZATION, { method: AuthorizationMethod.api_key, token: api_key })

    return this.http.get(url, { context }).pipe(
      map((res: any) => {
        return { data: res, url: returnedUrl };
      })
    );
  }

  saveRecordName(documentId: string, name: string, tenant_id: number, fullCheck = false): Observable<Document> {
    const listFilter = new ListFilter({
      fields: { documents: 'editable,lock_timeout,lock_user_id' }
    });

    const updatedDocument = new Document({
      id: documentId
    });
    updatedDocument.name = name;

    return this.findRecord(documentId, listFilter, tenant_id, { after_endpoint_url: 'validation' }).pipe(
      switchMap(document => iif(() =>
        this.isRenamingAllowed(document, fullCheck),
        this.saveRecord(updatedDocument, tenant_id, { after_endpoint_url: 'validation' }),
        of(null)
      ))
    );
  }

  proceed(document_id: string, tenant_id: number): Observable<void> {
    const urlVars: UrlVars = { postfix: 'proceed' };
    this.setProperties(tenant_id, urlVars);
    const url = this.buildUrl(document_id);

    return this.http.post<void>(url, {});
  }

  recordLockedOrUneditable(document: Document) {
    return this.isRecordLocked(document)
      || this.isRecordLockedByUser(document)
      || !document.editable;
  }

  private isRenamingAllowed(document: Document, fullCheck: boolean) {
   return fullCheck
          ? !this.recordLockedOrUneditable(document)
          : !this.isRecordLocked(document);
  }

  groupBy(groupBy: GroupByType, documents: Document[]): DocumentGroup[] {
    const groupActions = {
      name: () => this.groupByName(documents)
    };

    return groupBy && Array.isArray(documents) ? groupActions[groupBy]() : undefined;
  }

  private groupByName(documents: Document[]): DocumentGroup[] {
    const groups: { [groupName: string]: DocumentGroup } = {};
    const emptyGroup = {
      groupName: $localize `:@@common.empty:Empty`,
      records: [] as Document[],
      collapsed: false
    };

    documents.forEach(document => {
      const name = (document.name || '').split('/');

      if (name.length > 1) {
        const documentName = name.splice(name.length - 1, 1);
        document.shortened_name = documentName[0];
        const groupName = name.join('-');

        if (groups[groupName]) {
          groups[groupName].records.push(document);
        } else {
          groups[groupName] = {
            groupName: name.join('/'),
            records: [document],
            collapsed: false
          };
        }
      } else {
        emptyGroup.records.push(document);
      }
    });

    // const isNumber = (n) => {
    //   return !isNaN(parseFloat(n)) && isFinite(n);
    // };

    const sortedGroups = Object.keys(groups)
    .sort().map(key => groups[key]);
    // .sort((a, b) => {
    //   if (isNumber(a) && isNumber(b)) {
    //     return Number(a) - Number(b);
    //   } else if (isNumber(a)) {
    //     return -1;
    //   } else if (isNumber(b)) {
    //     return 1;
    //   } else {
    //     const x = a.toLowerCase();
    //     const y = b.toLowerCase();
    //     return x < y ? -1 : x > y ? 1 : 0;
    //   }
    // }).map(key => groups[key]);
    sortedGroups.unshift(emptyGroup);
    return sortedGroups;
  }
}
